const siteBaseUrl = 'engineering-wp.mobalab.net/'
const siteProtocol = 'https'
const siteFullUrl = `${siteProtocol}://${siteBaseUrl}`

// since using in gatsby-config, need to use node compatible syntax
module.exports = {
  siteBaseUrl,
  siteProtocol,
  siteFullUrl
}
