import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import hljs from 'highlight.js'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import NextPreviousPost from '../components/NextPreviousPost'
import Comments from '../components/Comments'
import CommentForm from '../components/CommentForm'
import SharingButtons from '../components/SharingButtons'
import 'highlight.js/styles/railscasts.css'
import TableOfContents from '../components/TableOfContents'

class BlogPostTemplate extends React.Component {
  
  componentDidMount() {
    document.querySelectorAll("pre").forEach(block => {
      hljs.highlightBlock(block)
    })
  }

  render() {
    const {
      content,
      categories,
      tags,
      title,
      date,
      author,
      eyeCatchImageUrl,
      pageContext,
      wordpressId,
    } = this.props

    // for code highlighting
    const replace = "brush: ([ a-z]*);";
    const re = new RegExp(replace,"g");
    let replacedContent = content.replace(re, "language-$1 hljs")

    const replaceLanguagePlane = "language-plain hljs";
    const rePlane = new RegExp(replaceLanguagePlane,"g");
    replacedContent = replacedContent.replace(rePlane, "plaintext")

    // tmp: for removing iframe compromising...
    // only remove internal iframe link
    const replaceIframe = "<iframe(.*?)src=(.*?)(https://engineering.mobalab.net)(.*?)height=\"(.*?)\"(.*?)/iframe>"
    const reIframe = new RegExp(replaceIframe, "g");
    replacedContent = content.replace(reIframe, '');

    const addBannerContent = '<center><a href="https://moba-pro.com/" target="_blank" rel="noopener noreferrer" ><img width="345px" height="130px" src="/img/moba-pro.png" alt="Moba Pro" /></a></center>';

    //This process will inserts banner in the middle of the article by counting all subtitles and dividing to half to insert banner
    let t = 0;
    var countOccurrence = (replacedContent.toString().match(/<h2>/g) || []).length;
    var selectMax = Math.round(countOccurrence/2);

    const FormatContent = replacedContent.toString().replace(/<h2>/g, function(match){
      t++;
      return (t === selectMax) ? addBannerContent + match : match;
    });

    return (
      <div className="post">
        <section className="section">
          {eyeCatchImageUrl !== null && (
            <div className="post__top-image-wrapper">
              <img src={eyeCatchImageUrl} alt="" className="post__top-image" />
            </div>
          )}
          <h1 className="post__title">
            {title}
          </h1>
          <p className="post__date-and-author">
            {`${date} - posted by `}
            <Link to={`/author/${author.slug}`}>{author.name}</Link>
          </p>
          <TableOfContents data={pageContext.tableOfContentsData} />
          {/**<a
            className="banner__image-wrapper"
            href="https://moba-pro.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="banner__image banner__image--medium"
              src="/img/banner-widget-lg.png"
              alt="Mobalab Pro"
            />
          </a>**/}
          <div id="post-body" dangerouslySetInnerHTML={{ __html: FormatContent }} />
          <p className="post__date-and-author">
            {`${date} - posted by `}
            <Link to={`/author/${author.slug}`}>{author.name}</Link>
          </p>
          <div style={{ marginTop: `4rem` }}>
            {/* commenting out categories */}
            {false && categories && categories.length ? (
              <div>
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      <Link to={`/categories/${category.slug}/`}>
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {tags && tags.length ? (
              <div>
                <h3>Tags</h3>
                <div className="tag__container">
                  {tags.map(tag => (
                    <div key={`${tag.slug}tag`} className="tag__item">
                      <Link to={`/tags/${tag.slug}/`} className="tag__link">{tag.name}</Link>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </section>
        <section className="section">
          <SharingButtons />
        </section>
        <section className="section">
          <NextPreviousPost
            pageContext={pageContext}
          />
        </section>
        <section className="section">
          <Comments wordpressId={wordpressId} />
        </section>
        <CommentForm wordpressId={wordpressId} />
      </div>
    )
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = (props) => {
  const { data, pageContext, location } = props
  const { wordpressPost: post } = data
  const { href: currentUrl } = location

  const replace = "<p>(.*)</p>"
  const re = new RegExp(replace,"g");
  const excerpt = post.excerpt.replace(re, "$1").replace('[&hellip;]', '...')

  let eyeCatchImageUrl = null
  try {
    eyeCatchImageUrl = post.featured_media.media_details.sizes.medium_large.source_url
  } catch(error) {
    // console.log(`no media_details with: ${post.title}`)
    try {
      eyeCatchImageUrl = post.featured_media.source_url
    } catch(error2) {
      // console.log(`no featured_media with: ${post.title}`)
    }
  }

  const [tableOfContentsData, setTableOfContentsData] = useState(pageContext.tableOfContentsData)

  let viewportHeight = 600
  if (typeof window !== 'undefined') {
    viewportHeight = window.innerHeight || document.documentElement.clientHeight
  }

  const currentHeadingIndexRef = useRef(null)

  const onScroll = () => {
    document.getElementById('post-body').querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((heading, index) => {
      const toTopPosition = heading.getBoundingClientRect().top
      const displayRate = toTopPosition / viewportHeight

      // if the 'current heading' is below the top 10% part in the viewport
      // the heading should NOT be 'current heading'
      // and the heading above should be 'current heading'
      if (displayRate >= 0.1) {
        if (index === currentHeadingIndexRef.current) {
          currentHeadingIndexRef.current = index === 0 ? null : index - 1
          setTableOfContentsData((existingTableOfContentsData) => {
            // set 'current heading' active and others inactive
            const updatingTableOfContentsData = existingTableOfContentsData.map((contents, tableDataIndex) => {
              return {...contents, active: tableDataIndex === currentHeadingIndexRef.current }
            })
            return updatingTableOfContentsData
          })
        }
      }

      // if the heading is on the top 10% part in the viewport
      // the heading should be 'current heading'
      if (displayRate < 0.1 && displayRate > 0) {
        if (index !== currentHeadingIndexRef.current ) {
          currentHeadingIndexRef.current = index
          setTableOfContentsData((existingTableOfContentsData) => {
            // set 'current heading' active and others inactive
            const updatingTableOfContentsData = existingTableOfContentsData.map((contents, tableDataIndex) => {
              return {...contents, active: tableDataIndex === currentHeadingIndexRef.current}
            })
            return updatingTableOfContentsData
          })
        }
      }
    });
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  })
  
  return (
    <Layout isPostPage tableOfContentsData={tableOfContentsData}>
      <Helmet title={`${post.title} – もばらぶエンジニアブログ`}>
        <meta name="description" content={excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content={eyeCatchImageUrl} />
      </Helmet>
      <BlogPostTemplate
        content={pageContext.replacedContent}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
        eyeCatchImageUrl={eyeCatchImageUrl}
        pageContext={pageContext}
        wordpressId={post.wordpress_id}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      excerpt
      slug
      dateObject: date
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
      author {
        name
        slug
      }
      featured_media {
        media_details {
          sizes {
            large {
              source_url
              height
              width
            }
            medium_large {
              source_url
              height
              width
            }
          }
        }
        source_url
      }
      wordpress_id
    }
  }
`
